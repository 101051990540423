<template>
  <div class="industryLanding">
    <el-row :gutter="10">
      <el-col :span="7">
        <div class="part_left">
          <chart-common :title="'指导城市更新'">
            <div class="part_left_content">
              <div class="radioList">
                <el-checkbox-group
                  v-model="checkedCities"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox label="xianzhuang">
                    <div class="radioClass">
                      现状用地
                      <img
                        src="@/assets/images/IndustryLanding/yellow_line.png"
                        alt=""
                      />
                    </div>
                  </el-checkbox>
                  <el-checkbox label="guihua" class="right_checkBox">
                    <div class="radioClass">
                      规划用地
                      <img
                        src="@/assets/images/IndustryLanding/blue_line.png"
                        alt=""
                      />
                    </div>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="optionsTopPart">
                <div class="content">
                  <el-checkbox-group
                  
                    v-model="checkedCities1"
                    @change="handleCheckedCitiesChange1"
                  >
                    <el-checkbox
                     class="checkbox"
                      v-for="city in cities"
                      :label="city"
                      :key="city"
                      >{{ city }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <div class="controlLine">
                <div class="infoTitle">
                  <img
                    src="../assets/images/industryMap/left/erji@2x.png"
                    alt=""
                  />
                  <span>控制线</span>
                </div>
                <div class="control_content">
                  <div class="itemList">
                    <div
                      class="itemOne"
                      v-for="(item, index) in controlList"
                      :key="index"
                    >
                      <img :src="getpath(item.pic)" alt="" />
                      <p class="word">{{ item.name }}</p>
                      <p class="num">{{ item.area || '--' }}㎡</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="park">
                <div class="infoTitle">
                  <img
                    src="../assets/images/industryMap/left/erji@2x.png"
                    alt=""
                  />
                  <span>工业园区</span>
                </div>
                <div class="park_content">
                  <div class="sum">
                    <div class="title">总统计</div>
                    <div class="totalNum">799<span>家</span></div>
                    <div class="selectList">
                      <div class="select-op-container">
                        <el-select v-model="value_gyyq" placeholder="请选择">
                          <el-option
                            v-for="item in gyyqOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>

                  <div class="tabs">
                    <div
                      :class="activedTab == 'left' ? 'left actived' : 'left'"
                      @click="selectTab('left')"
                    >
                      性质分类
                    </div>
                    <div
                      :class="activedTab == 'right' ? 'right actived' : 'right'"
                      @click="selectTab('right')"
                    >
                      街道分类
                    </div>
                  </div>
                  <div class="tabs_cont" v-if="activedTab == 'left'">
                    <div class="contOne">
                      <div class="contListOne">
                        国有园区
                        <span class="num">35</span>
                        <span class="unit">家</span>
                      </div>
                      <div class="contData">
                        <div class="contDataOne">
                          <div class="left">
                            <div class="title">占地面积（㎡）</div>
                            <div class="num">1266552</div>
                          </div>
                          <div class="line"></div>
                          <div class="right">
                            <div class="title">建筑面积（㎡）</div>
                            <div class="num">1302849</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="contOne">
                      <div class="contListOne">
                        民营园区
                        <span class="num">355</span>
                        <span class="unit">家</span>
                      </div>
                      <div class="contData">
                        <div class="contDataOne">
                          <div class="left">
                            <div class="title">占地面积（㎡）</div>
                            <div class="num">8030054</div>
                          </div>
                          <div class="line"></div>
                          <div class="right">
                            <div class="title">建筑面积（㎡）</div>
                            <div class="num">11281065</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="contOne">
                      <div class="contListOne">
                        集体园区
                        <span class="num">429</span>
                        <span class="unit">家</span>
                      </div>
                      <div class="contData">
                        <div class="contDataOne">
                          <div class="left">
                            <div class="title">占地面积（㎡）</div>
                            <div class="num">2522870</div>
                          </div>
                          <div class="line"></div>
                          <div class="right">
                            <div class="title">建筑面积（㎡）</div>
                            <div class="num">2625352</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tabs_cont" v-if="activedTab == 'right'">
                    <div
                      class="contOne"
                      v-for="(item, index) in list_jd"
                      :key="index"
                    >
                      <div class="contListOne">
                        {{ item.jd_name }}
                        <span class="num">{{ item.num }}</span>
                        <span class="unit">家</span>
                      </div>
                      <div class="contData">
                        <div class="contDataOne">
                          <div class="left">
                            <div class="title">占地面积（㎡）</div>
                            <div class="num">{{ item.area_zd }}</div>
                          </div>
                          <div class="line"></div>
                          <div class="right">
                            <div class="title">建筑面积（㎡）</div>
                            <div class="num">{{ item.area_jz }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </chart-common>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="part_center">
          <div id="map"></div>
        </div>
      </el-col>
      <el-col :span="7">
        <div class="part_right">
          <chart-common :title="'详情'">
            <div class="tabBar">
              <div
                :class="
                  selectedType == 0 ? 'itemOne actived_itemOne' : 'itemOne'
                "
                @click="selectItem(0)"
              >
                <span>现状用地信息</span>
              </div>
              <div
                :class="
                  selectedType == 1 ? 'itemOne actived_itemOne' : 'itemOne'
                "
                @click="selectItem(1)"
              >
                <span>规划用地信息</span>
              </div>
              <div
                :class="
                  selectedType == 2 ? 'itemOne actived_itemOne' : 'itemOne'
                "
                @click="selectItem(2)"
              >
                <span>重大项目</span>
              </div>
            </div>
            <div class="right_cont">
              <div class="itemList2">
                <ul>
                  <li v-for="(value, key) in itemList" :key="key">
                    <div class="title">
                      <img
                        src="../assets/images/industryMap/left/03@2x.png"
                        alt=""
                      />
                      <span>{{ key }}</span>
                    </div>
                    <p>{{ value }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </chart-common>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
const cityOptions = [
  '工业区快线',
  '土地整备',
  '"双百"高品质产业空间',
  '土地提容',
  '城市更新十四五规划范围线',
  '净地出让',
  '城市控制线',
  '三线一单',
]
import L from 'leaflet'
import '@supermap/iclient-leaflet'
import 'leaflet-editable'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import chartCommon from '@/components/chartCommon/index'
export default {
  components: {
    chartCommon
  },
  data () {
    return {
      popupContent:`<div class="popup">
              <div class="echart-container">
                <div class="cont_title">坪山沙湖“整村统筹”土地整备项目</div>
                <p>总投资: 19亿元</p>
                <p>用地面积: 2.6万㎡</p>
                <p>建筑面积: 10.8万㎡</p>
                <p>进度: 正在实施</p>
                <p class="last_descr">项目简介: 该项目将通过“土地+规划+资金”三位一体的方式，统筹解决社区转型发展，提升社区居民生活环境，完善公共配套设施水平。</p>
              </div>
            </div>`,
      cities: cityOptions,
      value_gyyq: 2022,
      layerQueryWhich: [],
      gyyqOptions: [{ label: '2022年第一季度', value: 2022 }],
      map_xzyd: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/xzcyyd@ps`,
      map_ghyd: `${window.BASE_URL.superMapUrl}/iserver/services/map-ps/rest/maps/ghcyyd@ps`,
      mapDataUrl_xzyd: `${window.BASE_URL.superMapUrl}/iserver/services/data-ps/rest/data`,
      // dataList_xzyd:[],
      initData_dataList_xzyd: {
        街道: '--',
        社区: '--',
        网格编号: '--',
        网格面积: '--',
        '建筑物数量（栋）': '--',
        建筑面积: '--',
        现状容积率: '--',
        开发强度: '--',
        确权用地面积: '--',
        确权比例: '--',
        未确权面积: '--',
        宗地号: '--',
        宗地数目: '--',
        合同类型: '--',
        备注: '--'
      },
      dataList_xzyd: {
        街道: '--',
        社区: '--',
        网格编号: '--',
        网格面积: '--',
        '建筑物数量（栋）': '--',
        建筑面积: '--',
        现状容积率: '--',
        开发强度: '--',
        确权用地面积: '--',
        确权比例: '--',
        未确权面积: '--',
        宗地号: '--',
        宗地数目: '--',
        合同类型: '--',
        备注: '--'
      },
      dataList_ghyd: {
        街道: '--',
        社区: '--',
        地块编号: '--',
        法定图层编码: '--',
        规划用地性: '--',
        规划用地面积: '--',
        规划容积率: '--',
        配套设施: '--',
        二类用地性: '--',
        备注: '--'
      },

      // zdProjectList: [
      //   { name: '街道', value: '--' },
      //   { name: '社区', value: '--' },
      //   { name: '网格编号', value: '--' },
      //   { name: '用地属性', value: '--' },
      //   { name: '权利人', value: '--' },
      //   { name: '项目用地面积', value: '--' },
      //   { name: '规划用地面积', value: '--' },
      //   { name: '容积率', value: '--' },
      //   { name: '项目类型', value: '--' },
      //   { name: '预计产值', value: '--' },
      //   { name: '报建单位', value: '--' },
      //   { name: '项目进度', value: '--' },
      //   { name: '项目简介', value: '--' }
      // ],
      zdProjectList: {
        街道: '--',
        社区: '--',
        网格编号: '--',
        用地属性: '--',
        权利人: '--',
        项目用地面积: '--',
        规划用地面积: '--',
        容积率: '--',
        项目类型: '--',
        预计产值: '--',
        报建单位: '--',
        项目进度: '--',
        项目简介: '--'
      },
      industryType: '0',
      selectedType: 0,
      activedTab: 'left',
      checkedCities: [],
      checkedCities1: [],
      controlList: [
        {
          name: '控制线用地面积',
          area: '--',
          pic: '01'
        },
        {
          name: '确权用地面积',
          area: '--',
          pic: '02'
        },
        {
          name: '现状工业用地面积',
          area: '--',
          pic: '03'
        },
        {
          name: '现状仓储物流用地',
          area: '--',
          pic: '04'
        },
        {
          name: '现状商业用地面积',
          area: '--',
          pic: '05'
        },
        {
          name: '现状其他用地面积',
          area: '--',
          pic: '06'
        }
      ],
      list_jd: [
        {
          jd_name: '坪山街道',
          num: '36',
          area_zd: '339300',
          area_jz: '444000'
        },
        {
          jd_name: '坑梓街道',
          num: '255',
          area_zd: '1417070',
          area_jz: '1899229'
        },
        {
          jd_name: '龙田街道',
          num: '134',
          area_zd: '3075121',
          area_jz: '4777669'
        },
        {
          jd_name: '石井街道',
          num: '165',
          area_zd: '200901',
          area_jz: '125296'
        },
        {
          jd_name: '马峦街道',
          num: '59',
          area_zd: '2647101.77',
          area_jz: '3483966'
        },
        {
          jd_name: '碧玲街道',
          num: '150',
          area_zd: '350560',
          area_jz: '550904'
        }
      ],
      ghyd: '',
      xzyd: '',
      resultLayer: '',
      querygroup: '',
      querygroup2: null,
      querygroup3: null,
      cluster: '',
      value2: '',
      jiedao: '',
      yuanqu: '',
      build: '',
      landLayerGroup: ''
    }
  },
  created () {
    this.selectItem(0)
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const vm = this
      // eslint-disable-next-line no-undef
      var dianzitu = L.supermap.tiandituTileLayer({
        url:
          'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })

      var tianditu = L.supermap.tiandituTileLayer({
        layerType: 'img',
        url:
          'http://t0.tianditu.gov.cn/img_c/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var labelMap = L.supermap.tiandituTileLayer({
        isLabel: true,
        url:
          'http://t{s}.tianditu.com/{layer}_{proj}/wmts?tk=c0ae855abbcd4b33d10e11e3af914aa7'
      })
      var tdtDz = L.layerGroup([labelMap, dianzitu])
      var tdtYx = L.layerGroup([tianditu, labelMap])
      // var qu = L.supermap.tiledMapLayer(this.map_qu)
      this.jiedao = L.supermap.tiledMapLayer(this.map_jd)
      var qujie = L.supermap.tiledMapLayer(this.map_qujie)
      this.yuanqu = L.supermap.tiledMapLayer(this.map_yuanqu)
      this.build = L.supermap.tiledMapLayer(this.map_build)
      this.ghyd = L.supermap.tiledMapLayer(this.map_ghyd)
      this.xzyd = L.supermap.tiledMapLayer(this.map_xzyd)
      // var labelTextCollision = L.LabelTextCollision({
      //   collisionFlg: true
      // })

      this.map = L.map('map', {
        preferCanvas: true,
        crs: L.CRS.EPSG4326,
        center: [22.7152291384486, 114.347931662042],
        maxZoom: 16,
        zoom: 11,
        // layers: [tdtYx, qujie,gaoxin],
        layers: [tdtYx],
        zoomControl: false,
        control: ['zoomControl']
      })
      this.querygroup = L.layerGroup().addTo(this.map)
      // this.ghyd.addTo(this.querygroup)
      var baseMaps = {
        电子地图: tdtDz,
        影像图: tdtYx
      }
      var overlayMaps = {
        街道: this.jiedao,
        区界: qujie,
        园区: this.yuanqu,
        楼栋: this.build
      }
      // L.control.layers(baseMaps, overlayMaps).addTo(this.map)
      // L.marker([22.7152291384486, 114.347931662042]).addTo(this.map)
      this.querygroup2 = L.layerGroup().addTo(this.map)
      this.querygroup3 = L.layerGroup().addTo(this.map)
      this.landLayerGroup = L.layerGroup().addTo(this.map)
      this.map.on('click', function (evt) {
        vm.x = evt.latlng.lng
        vm.y = evt.latlng.lat
        vm.latlng = evt.latlng
        vm.Sqlquery()
      })
    },
    /**
     * 描述 点击查询调用的方法
     * @date 2022-04-12
     * @returns {any}
     */
    Sqlquery () {
      const vm = this
      var popupContent = null
      var marker
      var queryDataSet = []
      //   var currentLayer
      if (this.querygroup2 != null) {
        this.querygroup2.clearLayers()
      }
      var point = L.circle([vm.y, vm.x], {
        radius: 1
      })
      if (vm.layerQueryWhich.length > 0) {
        if (vm.layerQueryWhich.length == 2) {
          queryDataSet = [
            'pingshan_规划产业用地:pingshan_规划产业用地',
            'pingshan_现状产业用地:pingshan_现状产业用地'
          ]
        } else {
          if (vm.layerQueryWhich[0] == 'xianzhuang') {
            queryDataSet = ['pingshan_现状产业用地:pingshan_现状产业用地']
          } else if (vm.layerQueryWhich[0] == 'guihua') {
            queryDataSet = ['pingshan_规划产业用地:pingshan_规划产业用地']
          }
        }
      }
      // eslint-disable-next-line no-undef
      var geometryParam = new SuperMap.GetFeaturesByGeometryParameters({
        datasetNames: queryDataSet,
        // 'pingshan_规划产业用地:pingshan_规划产业用地',
        // 'pingshan_现状产业用地:pingshan_现状产业用地'
        // 'baoan_1:T20201022_宝安区园区_1'
        // 'yuanqu:bldg'
        // 顺序有关系,把楼栋放在后面,那么点击楼栋查询的时候才能查到.
        // 如果放在前面,那么点击查询的时候 会查到园区,点不到楼栋,被覆盖了.
        // 放在后面的查询层级比较高
        // "zhuzhaibianjie:GCS_2000_所有住宅小区的边界20201019",
        // 这里data就是数据源  资溪就是数据集
        geometry: point,
        spatialQueryMode: 'INTERSECT'
      })
      L.supermap
        .featureService(this.mapDataUrl_xzyd)
        .getFeaturesByGeometry(geometryParam, function (serviceResult) {
          if (serviceResult.result) {
            L.geoJSON(serviceResult.result.features, {
              onEachFeature: function (feature, layer) {
                console.log(feature)
                var tempDataObj
                var data = feature.properties
                if (data.FID_1) {
                  //fid 现状
                  tempDataObj = {
                    街道: data.街道,
                    社区: data.社区,
                    网格编号: data.网格编号,
                    网格面积: data.网格面,
                    '建筑物数量（栋）': data.建筑物,
                    建筑面积: data.建筑面,
                    现状容积率: data.容积率,
                    开发强度: data.开发强,
                    确权用地面积: data.确权面,
                    确权比例: data.确权比,
                    未确权面积: data.未确权,
                    宗地号: '--',
                    宗地数目: data.宗地数目,
                    合同类型: data.合同类型,
                    备注: '--'
                  }
                  vm.dataList_xzyd = tempDataObj
                  vm.selectItem(0)
                } else {
                  // 规划
                  tempDataObj = {
                    街道: data.街道,
                    社区: data.社区,
                    地块编号: data.地块编号,
                    法定图层编码: data.法定图层编,
                    规划用地性: data.规划用地性,
                    规划用地面积: data.规划用地面,
                    规划容积率: data.规划容积率,
                    配套设施: data.配套设施,
                    二类用地性: data.二类用地性,
                    备注: data.备注
                  }
                  vm.dataList_ghyd = tempDataObj
                  vm.dataList_xzyd = vm.initData_dataList_xzyd
                  vm.selectItem(1)
                }

                vm.itemList = tempDataObj
                vm.$forceUpdate()
                console.log('this.itemList :>> ', this.itemList)
                // dataList_xzyd
                // currentLayer = layer
                // vm.getBuildingsByParkBounds(currentLayer)
                vm.popupContent = `<div class="popup">
              <div class="echart-container">
                <div class="cont_title">坪山沙湖“整村统筹”土地整备项目</div>
                <p>总投资: 19亿元</p>
                <p>用地面积: 2.6万</p>
                <p>建筑面积: 10.8万</p>
                <p>进度: 正在实施</p>
                <p class="last_descr">项目简介: 该项目将通过“土地+规划+资金”三位一体的方式，统筹解决社区转型发展，提升社区居民生活环境，完善公共配套设施水平。</p>
              </div>
            </div>`
              }
            }).addTo(vm.querygroup2)
          }
          if (vm.popupContent != null) {
            // marker = L.marker(vm.latlng).addTo(vm.querygroup2)
            // marker.bindPopup(vm.popupContent).openPopup()
          }
        })
    },
    selectTab (val) {
      this.activedTab = val
    },
    getpath (path) {
      return path
        ? require('@/assets/images/IndustryLanding/' + path + '.png')
        : '' //path不为null就返回对应的路径
    },
    selectItem (type) {
      this.selectedType = type
      if (this.querygroup3 != null) {
        this.querygroup3.clearLayers()
      }
      switch (type) {
        case 0:
          this.itemList = this.dataList_xzyd
          break
        case 1:
          this.itemList = this.dataList_ghyd
          break
        case 2:
          this.itemList = this.zdProjectList
          var marker = L.marker([22.7152291384486, 114.347931662042]).addTo(this.querygroup3)
            marker.bindPopup(this.popupContent).openPopup()
          break

        default:
          break
      }
    },
    handleCheckedCitiesChange1() {},
    handleCheckedCitiesChange (e) {
      this.landLayerGroup.clearLayers()
      this.layerQueryWhich = e
      if (e.length > 0) {
        if (e.length == 2) {
          this.ghyd.addTo(this.landLayerGroup)
          this.xzyd.addTo(this.landLayerGroup)
        } else {
          if (e[0] == 'xianzhuang') {
            this.xzyd.addTo(this.landLayerGroup)
          } else if (e[0] == 'guihua') {
            this.ghyd.addTo(this.landLayerGroup)
          }
        }
      }
    }
  }
}
</script>
<style lang="less">
.select-op-container {
  width: 9.5rem /* 140/16 */;
  height: 1.75rem /* 28/16 */;
  .el-select {
    width: 100%;
    height: 100%;
    .el-input {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        color: #fefeff;
        background: #103552;
        border: 1px solid #159aff;
      }
      .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
}
.el-select-dropdown {
  .el-scrollbar__wrap {
    overflow-x: auto;
  }
}
</style>
<style lang="less" scoped>
#map {
  width: 100%;
  height: 100%;
}
.industryLanding {
  padding: 1.875rem /* 30/16 */;
  height: 60rem /* 960/16 */;
  .part_left {
    height: 60rem /* 960/16 */;
    .part_left_content {
      padding: 1.25rem /* 20/16 */ 0.625rem /* 10/16 */;
      .radioList {
        width: 100%;
        height: 2.5rem /* 40/16 */;
        background: #5682a3;
        border: 1px solid #159aff;
        box-shadow: inset 0px 0px 16px 0px #159aff;
        padding: 0.625rem /* 10/16 */;
        display: flex;
        justify-content: space-between;

        .radioClass {
          color: #fff;
        }
        .right_checkBox {
          margin-left: 3.75rem /* 60/16 */;
        }
      }
      .optionsTopPart {
        padding-left: 1.25rem /* 20/16 */;
        .content {
          width: 100%;
          display: flex;

          .checkbox {
            width: 45%;
            margin-top: 1.25rem /* 20/16 */;
          }
        }
      }
      .controlLine,
      .park {
        margin-top: 1.25rem /* 20/16 */;

        .infoTitle {
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          span {
            position: absolute;
            top: 0.375rem /* 6/16 */;
            left: 0.625rem /* 10/16 */;
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #3be8ff;
          }
        }
        .control_content {
          .itemList {
            padding: 0px /* 20/16 */ 0.9375rem /* 15/16 */;
            display: flex;
            flex-wrap: wrap;
            .itemOne {
              margin-top: 1.0625rem /* 17/16 */;
              width: 33.3%;
              display: flex;
              align-items: center;
              flex-direction: column;
              img {
                width: 2.625rem /* 42/16 */;
                height: 3.0625rem /* 49/16 */;
              }
              .word {
                font-size: 1rem /* 16/16 */;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #d0deee;
              }
              .num {
                font-size: 0.875rem /* 14/16 */;
                font-family: PangMenZhengDao;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }
      .park {
        .park_content {
          padding: 1.25rem /* 20/16 */;
          .sum {
            display: flex;
            width: 100%;
            height: 3.125rem /* 50/16 */;
            background: #0f3753;
            box-shadow: inset 0px 6px 18px 0px rgba(21, 154, 255, 0.9);
            // opacity: 0.7;
            padding: 0 /* 16/16 */ 0.5625rem /* 9/16 */;
            align-items: center;
            justify-content: space-between;
            .title {
              font-size: 1rem;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
            }
            .totalNum {
              font-size: 1.25rem /* 20/16 */;
              font-family: PangMenZhengDao;
              font-weight: 400;
              color: #3be8ff;
              span {
                font-size: 1rem /* 14/16 */;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #d0deee;
              }
            }
          }
          .tabs {
            margin-top: 1.25rem /* 20/16 */;
            display: flex;
            .left,
            .right {
              cursor: pointer;
              font-size: 1rem;
              font-family: Source Han Sans CN;
              color: #d0deee;
              margin-right: 1.875rem /* 30/16 */;
            }
            .actived {
              font-weight: bold;
              font-size: 1rem;
              font-family: Source Han Sans CN;
              color: #159aff;
              border-bottom: 2px solid #159aff;
            }
          }
          .tabs_cont {
            margin-top: 1rem /* 16/16 */;
            height: 13.5rem /* 360/16 */;
            overflow-y: scroll;
            .contOne {
              // height: 4.1875rem /* 67/16 */;
            }
            .contListOne {
              background: url('../assets/images/IndustryLanding/07.png')
                no-repeat;
              background-size: cover;
              font-size: 1rem;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              .num {
                font-size: 1.25rem /* 20/16 */;
                font-family: PangMenZhengDao;
                font-weight: 400;
                color: #3be8ff;
                margin-left: 0.6875rem /* 11/16 */;
                margin-right: 0.625rem /* 10/16 */;
              }
              .unit {
                font-size: 0.875rem /* 14/16 */;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #d0deee;
              }
            }
            .contData {
              display: flex;
              margin-bottom: 1.25rem /* 20/16 */;
              padding-top: 0.625rem /* 10/16 */;
              .contDataOne {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                .left,
                .right {
                  display: flex;
                  flex-direction: column;
                  .title {
                    font-size: 0.875rem /* 14/16 */;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #d0deee;
                  }
                  .num {
                    font-size: 1rem;
                    font-family: PangMenZhengDao;
                    font-weight: 400;
                    color: #fffbf1;
                  }
                }
                .line {
                  width: 1px;
                  height: 1.75rem /* 28/16 */;
                  background: #637e9d;
                }
              }
            }
          }
        }
      }
    }
  }
  .part_center {
    height: 60rem;
  }
  .part_right {
    height: 60rem;
    .tabBar {
      margin-top: 1.25rem /* 20/16 */;
      display: flex;
      line-height: 3.125rem /* 50/16 */;
      height: 3.125rem /* 50/16 */;
      background: url('../assets/images/industryMap/left/02@2x.png');
      // padding: 0 10px;
      .itemOne {
        align-items: center;
        padding: 0 1.4375rem /* 23/16 */;
        width: 33.3%;
        text-align: center;
        cursor: pointer;

        img {
          width: 1.25rem /* 20/16 */;
          height: 1.125rem /* 18/16 */;
          margin-right: 0.375rem /* 6/16 */;
        }
        span {
          font-size: 1rem /* 16/16 */;
          font-family: Source Han Sans CN;
          font-weight: normal;

          color: #d0deee;
        }
      }
      .actived_itemOne {
        background: url('../assets/images/industryMap/left/ditu@2x.png')
          no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        span {
          font-size: 1rem /* 16/16 */;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .right_cont {
      .itemList2 {
        margin-top: 0.8125rem /* 13/16 */;
        padding-left: 0.625rem /* 10/16 */;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 50%;
            margin-top: 1.1875rem /* 19/16 */;
            .title {
              position: relative;
              img {
                height: 0.625rem /* 10/16 */;
              }
              span {
                font-size: 1rem /* 14/16 */;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #d0deee;
                position: absolute;
                top: 0.125rem /* 2/16 */;
                left: 0.1875rem /* 3/16 */;
              }
            }
            p {
              font-size: 0.875rem /* 14/16 */;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              margin-top: 0.3125rem /* 5/16 */;
              padding-left: 0.125rem /* 2/16 */;
            }
          }
        }
      }
    }
  }
}
</style>
